export const currenciesMap = {
  PLN: 'Polski złoty',
  EUR: 'Euro',
  USD: 'Dolar amerykański',
  CHF: 'Frank szwajcarski',
  ARS: 'Peso argentyńskie',
  GBP: 'Funt brytyjski',
  AUD: 'Dolar australijski',
  BGN: 'Lew bułgarski',
  BRL: 'Real brazylijski',
  CAD: 'Dolar kanadyjski',
  CZK: 'Korona czeska',
  DKK: 'Korona duńska',
  HKD: 'Dolar hongkoński',
  HRK: 'Kuna chorwacka',
  MXN: 'Peso meksykańskie',
  NOK: 'Korona norweska',
  NZD: 'Dolar nowozelandzki',
  PEN: 'Sol peruwiański',
  RON: 'Lej rumuński',
  RUB: 'Rubel rosyjski',
  SEK: 'Korona szwedzka',
  SGD: 'Dolar singapurski',
  TRY: 'Lira turecka',
  UYU: 'Peso urugwajskie',
  ZAR: 'Rand południowoafrykański',
}

export const currenciesCountriesMap = {
  PLN: 'Polska',
  EUR: 'Europa',
  USD: 'Stany Zjednoczone',
  CHF: 'Szwajcaria',
  ARS: 'Argentyna',
  GBP: 'Wielka Brytania',
  AUD: 'Australia',
  BGN: 'Bułgaria',
  BRL: 'Brazylia',
  CAD: 'Kanada',
  CZK: 'Czechy',
  DKK: 'Dania',
  HKD: 'Hongkong',
  HRK: 'Chorwacja',
  MXN: 'Meksyk',
  NOK: 'Norwegia',
  NZD: 'Nowa Zelandia',
  PEN: 'Peru',
  RON: 'Rumunia',
  RUB: 'Rosja',
  SEK: 'Szwecja',
  SGD: 'Singapur',
  TRY: 'Turcja',
  UYU: 'Urugwaj',
  ZAR: 'RPA',
}

const currencyHelper = {
  getCurrencyName: (currency: string): string => currenciesMap[currency] || currency,
  getCurrencyCountry: (currency: string): string => currenciesCountriesMap[currency] || currency,
}

export default currencyHelper
