export default {
  calculator: {
    buyLabelName: 'mam',
    sellLabelName: 'otrzymam',
    currentRate: 'Aktualny kurs:',
    continueButton: 'Kontynuuj wymianę',
    miniChart: 'Mini-wykres pokazuje wahania średniego kursu z ostatnich 7 dni',
    sellTooltip:
      'To kurs sprzedaży - sprzedajemy Ci {{currency}} po {{rate}} PLN. Im niższy kurs tym bardziej korzystny dla Ciebie. Automatyczna aktualizacja co 10 sekund.',
    buyTooltip:
      'To kurs kupna -kupujemy od Ciebie {{currency}} po {{rate}} PLN. Im wyższy kurs tym bardziej korzystny dla Ciebie. Automatyczna aktualizacja co 10 sekund.',
  },
  preactSelect: {
    search: 'Szukaj...',
  },
  frontpageExitPopup: {
    accountExists: 'Wygląda na to, że masz już konto. Zniżkę wysyłamy dla nowych Klientów.',
    error: 'Wystąpił błąd',
  },
  emailValidator: {
    requiredField: 'Konieczne jest zaznaczenie tego pola',
    notEmptyField: 'To pole nie może być puste',
    minValue: 'Minimalna wartość to',
    maxValue: 'Makymalna wartość to',
    incorrectEmail: 'Podaj poprawny adres email',
    toShortPassowrd: 'Hasło jest za krótkie',
  },
  currencyAlert: {
    isLower: 'będzie niższy',
    isHigher: 'będzie wyższy',
    mustBeLessThan: 'Kurs musi być liczbą mniejszą od 99,9999',
    alertAlreadyExists: 'Alert kursowy dla podanego kursu już istnieje',
    maxAlerts: 'Nie możesz zapisać się na większą ilość alertów. (max. 10)',
    error: 'Coś poszło nie tak, spróbuj za chwilę.',
    alertHash: 'alert-walutowy',
  },
  ratesUpdate: {
    error: 'Nie udało się pobrać danych. Ponawiam za',
    timeUnit: 'sek.',
  },
  currencies: {
    BGN: 'Lew bułgarski',
    CHF: 'Frank szwajcarski',
    CZK: 'Korona czeska',
    DKK: 'Korona duńska',
    EUR: 'Euro',
    GBP: 'Funt brytyjski',
    NOK: 'Korona norweska',
    PLN: 'Polski złoty',
    RON: 'Lej rumuński',
    SEK: 'Korona szwedzka',
    USD: 'Dolar amerykański',
    ZAR: 'Rand południowoafrykański',
    TRY: 'Lira turecka',
    SGD: 'Dolar singapurski',
    RUB: 'Rubel rosyjski',
    NZD: 'Dolar nowozelandzki',
    MXN: 'Peso meksykańskie',
    HRK: 'Kuna chorwacka',
    HKD: 'Dolar hongkoński',
    CAD: 'Dolar kanadyjski',
    AUD: 'Dolar australijski',
    HUF: 'Forint węgierski',
    CNY: 'Juan chiński',
    ILS: 'Nowy szekel izraelski',
    JPY: 'Jen japoński',
    ARS: 'Peso argentyńskie',
    BRL: 'Real brazylijski',
    CLP: 'Peso chilijskie',
    COP: 'Peso kolumbijskie',
    UYU: 'Peso urugwajskie',
    PEN: 'Sol peruwiański',
  },
  chart: {
    time: 'Czas',
    rate: 'Kurs',
    opening: 'Otwarcie',
    highest: 'Najwyższy',
    lowest: 'Najniższy',
    closing: 'Zamknięcie',
    monthNames: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
    dayNames: ['pon', 'wto', 'śro', 'czw', 'pią', 'sob', 'nie'],
  },
  landingForm: {
    show: 'Pokaż',
    hide: 'Ukryj',
    requiredField: 'Konieczne jest zaznaczenie tego pola',
    notEmptyField: 'To pole nie może być puste',
    incorrectEmail: 'Podaj poprawny adres email',
    toShortPassowrd: 'Hasło jest za krótkie',
    invalidPromocode: 'Promocja dostępna na tej stronie się skończyła - zarejestruj się przez stronę główną',
    emailAlreadyExists: 'Ten e&#8209;mail nie może być użyty do rejestracji',
    generalInvalid: 'Coś poszło nie tak, spróbuj ponownie później',
  },
}
