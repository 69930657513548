export default {
  currency: {
    zl: 'PLN',
  },
  calculator: {
    buyLabelName: 'mam',
    sellLabelName: 'otrzymam',
    currentRate: 'Aktualny kurs:',
    continueButton: 'Kontynuuj wymianę',
    miniChart: 'Mini-wykres pokazuje wahania średniego kursu z ostatnich 7 dni',
    sellTooltip:
      'To kurs sprzedaży - sprzedajemy Ci {{currency}} po {{rate}} PLN. Im niższy kurs tym bardziej korzystny dla Ciebie. Automatyczna aktualizacja co 10 sekund.',
    buyTooltip:
      'To kurs kupna -kupujemy od Ciebie {{currency}} po {{rate}} PLN. Im wyższy kurs tym bardziej korzystny dla Ciebie. Automatyczna aktualizacja co 10 sekund.',
    connectionError: 'Problem z pobraniem kursów walut. Sprawdź czy masz połączenie z internetem.',
    routeFusionInfo: 'Aktualnie sprzedaż {{currencyName}} {{baseCurrency}} jest niedostępna',
  },
  preactSelect: {
    search: 'Szukaj...',
  },
  frontpageExitPopup: {
    accountExists: 'Wygląda na to, że masz już konto. Zniżkę wysyłamy dla nowych Klientów.',
    error: 'Wystąpił błąd',
  },
  emailValidator: {
    requiredField: 'Konieczne jest zaznaczenie tego pola',
    notEmptyField: 'To pole nie może być puste',
    minValue: 'Minimalna wartość to',
    maxValue: 'Makymalna wartość to',
    incorrectEmail: 'Podaj poprawny adres email',
    toShortPassowrd: 'Hasło jest za krótkie',
  },
  currencyAlert: {
    isLower: 'będzie niższy',
    isHigher: 'będzie wyższy',
    mustBeLessThan: 'Kurs musi być liczbą mniejszą od 99,9999',
    alertAlreadyExists: 'Alert kursowy dla podanego kursu już istnieje',
    maxAlerts: 'Nie możesz zapisać się na większą ilość alertów. (max. 10)',
    error: 'Coś poszło nie tak, spróbuj za chwilę.',
    alertHash: 'alert-walutowy',
  },
  ratesUpdate: {
    error: 'Nie udało się pobrać danych. Ponawiam za',
    timeUnit: 'sek.',
  },
  currencies: {
    BGN: 'Lew bułgarski',
    CHF: 'Frank szwajcarski',
    CZK: 'Korona czeska',
    DKK: 'Korona duńska',
    EUR: 'Euro',
    GBP: 'Funt brytyjski',
    NOK: 'Korona norweska',
    PLN: 'Polski złoty',
    RON: 'Lej rumuński',
    SEK: 'Korona szwedzka',
    USD: 'Dolar amerykański',
    ZAR: 'Rand południowoafrykański',
    TRY: 'Lira turecka',
    SGD: 'Dolar singapurski',
    RUB: 'Rubel rosyjski',
    NZD: 'Dolar nowozelandzki',
    MXN: 'Peso meksykańskie',
    HRK: 'Kuna chorwacka',
    HKD: 'Dolar hongkoński',
    CAD: 'Dolar kanadyjski',
    AUD: 'Dolar australijski',
    HUF: 'Forint węgierski',
    CNY: 'Juan chiński',
    ILS: 'Nowy szekel izraelski',
    JPY: 'Jen japoński',
    ARS: 'Peso argentyńskie',
    BRL: 'Real brazylijski',
    CLP: 'Peso chilijskie',
    COP: 'Peso kolumbijskie',
    UYU: 'Peso urugwajskie',
    PEN: 'Sol peruwiański',
  },
  currenciesDopelniacz: {
    BGN: 'Lewa bułgarskiego',
    CHF: 'Franka szwajcarskiego',
    CZK: 'Korony czeskiej',
    DKK: 'Korony duńskiej',
    EUR: 'Euro',
    GBP: 'Funta brytyjskiego',
    NOK: 'Korony norweskiej',
    PLN: 'Polskiego złotego',
    RON: 'Leja rumuńskiego',
    SEK: 'Korony szwedzkiej',
    USD: 'Dolara amerykańskiego',
    ZAR: 'Randa południowoafrykańskiego',
    TRY: 'Liray tureckiej',
    SGD: 'Dolara singapurskiego',
    RUB: 'Rubla rosyjskiego',
    NZD: 'Dolara nowozelandzkiego',
    MXN: 'Peso meksykańskiego',
    HRK: 'Kuny chorwackiej',
    HKD: 'Dolara hongkońskiego',
    CAD: 'Dolara kanadyjskiego',
    AUD: 'Dolara australijskiego',
    HUF: 'Forinta węgierskiego',
    CNY: 'Juana chińskiego',
    ILS: 'Nowego szekla izraelskiego',
    JPY: 'Jena japońskiego',
    ARS: 'Peso argentyńskiego',
    BRL: 'Reala brazylijskiego',
    CLP: 'Peso chilijskiego',
    COP: 'Peso kolumbijskiego',
    UYU: 'Peso urugwajskiego',
    PEN: 'Sola peruwiańskiego',
  },
  chart: {
    time: 'Czas',
    rate: 'Kurs',
    opening: 'Otwarcie',
    highest: 'Najwyższy',
    lowest: 'Najniższy',
    closing: 'Zamknięcie',
    monthNames: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
    dayNames: ['pon', 'wto', 'śro', 'czw', 'pią', 'sob', 'nie'],
  },
  landingForm: {
    show: 'Pokaż',
    hide: 'Ukryj',
  },
  networkErrorStatus: {
    slow: 'Problem z pobraniem kursów walut. Wolne połączenie z internetem',
    noNetwork: 'Problem z pobraniem kursów walut. Sprawdź czy masz połączenie z internetem',
    default: 'Wystąpiły problemy techniczne. Skontatuj się z obsługą klienta',
  },
  calculatorForegin: {
    exchangeRates: 'Kurs wymiany',
    details: 'Szczegóły',
    amountYouSend: 'Kwota, którą wpłacam',
    countryYouSendMoney: 'Kraj, do którego wyślesz pieniądze',
    foreignTransferFee: 'Opłata za przelew zagraniczny',
    foreignTransferType: 'Typ przelewu zagranicznego',
    exchangeAmount: 'Kwota wymiany',
    amountRecipientReceive: 'Kwota, którą otrzyma odbiorca',
    minAmount: 'Kwota wymiany jest zbyt niska.',
    notSellCurrency: 'Sprzedaż waluty jest niedostępna.',
  },
  countries: {
    AD: 'Andora',
    AE: 'Zjednoczone Emiraty Arabskie',
    AF: 'Afganistan',
    AG: 'Antigua i Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Antyle Holenderskie',
    AO: 'Angola',
    AQ: 'Antarktyka',
    AR: 'Argentyna',
    AS: 'Samoa Amerykańskie',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Wyspy Alandzkie',
    AZ: 'Azerbejdżan',
    BA: 'Bośnia i Hercegowina',
    BB: 'Barbados',
    BD: 'Bangladesz',
    BE: 'Belgia',
    BF: 'Burkina Faso',
    BG: 'Bułgaria',
    BH: 'Bahrajn',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthélemy',
    BM: 'Bermudy',
    BN: 'Brunei Darussalam',
    BO: 'Boliwia',
    BR: 'Brazylia',
    BS: 'Bahamy',
    BT: 'Bhutan',
    BV: 'Wyspa Bouveta',
    BW: 'Botswana',
    BY: 'Białoruś',
    BZ: 'Belize',
    CA: 'Kanada',
    CC: 'Wyspy Kokosowe',
    CD: 'Demokratyczna Republika Konga',
    CF: 'Republika Środkowej Afryki',
    CG: 'Kongo',
    CH: 'Szwajcaria',
    CI: 'Wybrzeże Kości Słoniowej',
    CK: 'Wyspy Cooka',
    CL: 'Chile',
    CM: 'Kamerun',
    CN: 'Chiny',
    CO: 'Kolumbia',
    CR: 'Kostaryka',
    CU: 'Kuba',
    CV: 'Wyspy Zielonego Przylądka',
    CW: 'Curaçao',
    CX: 'Wyspa Bożego Narodzenia',
    CY: 'Cypr',
    CZ: 'Czechy',
    DE: 'Niemcy',
    DJ: 'Dżibuti',
    DK: 'Dania',
    DM: 'Dominika',
    DO: 'Republika Dominikańska',
    DZ: 'Algieria',
    EC: 'Ekwador',
    EE: 'Estonia',
    EG: 'Egipt',
    EH: 'Sahara Zachodnia',
    ER: 'Erytrea',
    ES: 'Hiszpania',
    ET: 'Etiopia',
    FI: 'Finlandia',
    FJ: 'Fidżi',
    FK: 'Falklandy',
    FM: 'Mikronezja',
    FO: 'Wyspy Owcze',
    FR: 'Francja',
    GA: 'Gabon',
    GB: 'Wielka Brytania',
    GD: 'Grenada',
    GE: 'Gruzja',
    GF: 'Gujana Francuska',
    GG: 'Wyspa Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Grenlandia',
    GM: 'Gambia',
    GN: 'Gwinea',
    GP: 'Gwadelupa',
    GQ: 'Gwinea Równikowa',
    GR: 'Grecja',
    GS: 'Georgia Południowa i Sandwich Południowy',
    GT: 'Gwatemala',
    GU: 'Guam',
    GW: 'Gwinea Bissau',
    GY: 'Gujana',
    HK: 'Hongkong',
    HM: 'Wyspy Heard i McDonalda',
    HN: 'Honduras',
    HR: 'Chorwacja',
    HT: 'Haiti',
    HU: 'Węgry',
    ID: 'Indonezja',
    IE: 'Irlandia',
    IL: 'Izrael',
    IM: 'Wyspa Man',
    IN: 'Indie',
    IO: 'Terytorium Brytyjskie Oceanu Indyjskiego',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Islandia',
    IT: 'Włochy',
    JE: 'Wyspa Jersey',
    JM: 'Jamajka',
    JO: 'Jordania',
    JP: 'Japonia',
    KE: 'Kenia',
    KG: 'Kirgistan',
    KH: 'Kambodża',
    KI: 'Kiribati',
    KM: 'Komory',
    KN: 'Saint Kitts i Nevis',
    KP: 'Korea Północna',
    KR: 'Korea Południowa',
    KW: 'Kuwejt',
    KY: 'Kajmany',
    KZ: 'Kazachstan',
    LA: 'Laos',
    LB: 'Liban',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litwa',
    LU: 'Luksemburg',
    LV: 'Łotwa',
    LY: 'Libia',
    MA: 'Maroko',
    MC: 'Monako',
    MD: 'Mołdawia',
    ME: 'Czarnogóra',
    MF: 'Sint Maarten',
    MG: 'Madagaskar',
    MH: 'Wyspy Marshalla',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Birma',
    MN: 'Mongolia',
    MO: 'Makau, Specjalny Region Administracyjny Chin',
    MP: 'Mariany Północne',
    MQ: 'Martynika',
    MR: 'Mauretania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Malediwy',
    MW: 'Malawi',
    MX: 'Meksyk',
    MY: 'Malezja',
    MZ: 'Mozambik',
    NA: 'Namibia',
    NC: 'Nowa Kaledonia',
    NE: 'Niger',
    NF: 'Wyspa Norfolk',
    NG: 'Nigeria',
    NI: 'Nikaragua',
    NL: 'Holandia',
    NO: 'Norwegia',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nowa Zelandia',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Polinezja Francuska',
    PG: 'Papua Nowa Gwinea',
    PH: 'Filipiny',
    PK: 'Pakistan',
    PL: 'Polska',
    PM: 'Saint-Pierre i Miquelon',
    PN: 'Pitcairn',
    PR: 'Portoryko',
    PS: 'Palestyna',
    PT: 'Portugalia',
    PW: 'Palau',
    PY: 'Paragwaj',
    QA: 'Katar',
    RE: 'Reunion',
    RO: 'Rumunia',
    RS: 'Serbia',
    RU: 'Rosja',
    RW: 'Rwanda',
    SA: 'Arabia Saudyjska',
    SB: 'Wyspy Salomona',
    SC: 'Seszele',
    SD: 'Sudan',
    SE: 'Szwecja',
    SG: 'Singapur',
    SH: 'Wyspa Świętej Heleny',
    SI: 'Słowenia',
    SJ: 'Svalbard i Jan Mayen',
    SK: 'Słowacja',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    ST: 'Wyspy Świętego Tomasza i Książęca',
    SV: 'Salwador',
    SY: 'Syria',
    SZ: 'Suazi',
    TC: 'Turks i Caicos',
    TD: 'Czad',
    TF: 'Francuskie Terytoria Południowe',
    TG: 'Togo',
    TH: 'Tajlandia',
    TJ: 'Tadżykistan',
    TK: 'Tokelau',
    TL: 'Timor Wschodni',
    TM: 'Turkmenistan',
    TN: 'Tunezja',
    TO: 'Tonga',
    TR: 'Turcja',
    TT: 'Trynidad i Tobago',
    TV: 'Tuvalu',
    TW: 'Tajwan',
    TZ: 'Tanzania',
    UA: 'Ukraina',
    UG: 'Uganda',
    UM: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
    US: 'Stany Zjednoczone',
    UY: 'Urugwaj',
    UZ: 'Uzbekistan',
    VA: 'Watykan',
    VC: 'Saint Vincent i Grenadyny',
    VE: 'Wenezuela',
    VG: 'Brytyjskie Wyspy Dziewicze',
    VI: 'Wyspy Dziewicze Stanów Zjednoczonych',
    VN: 'Wietnam',
    VU: 'Vanuatu',
    WF: 'Wallis i Futuna',
    WS: 'Samoa',
    XK: 'Kosowo',
    YE: 'Jemen',
    YT: 'Majotta',
    ZA: 'Republika Południowej Afryki',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
}
